.legend-container-style {
  z-index: 1;
  width: 44px;
  position: absolute;
  justify-content: flex-start;
  width: 70%;
  top: 50px;
  left: 166px;
}
.legend-button-style {
  border: none;
  background-color: #fff;
  font-size: 13px;
  color: #5a5a5a;
}
