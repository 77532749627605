/* html,
body {
  font-family: sans-serif;
  margin: 0;
  padding: 0.5rem;
}

h1 {
  margin: 0;
} */

.multi-select {
  max-width: 300px;
}