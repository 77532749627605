.d-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
input:focus {
  outline: none !important;
  background-color: #fafafa !important;
}
.req-drop-down {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid #ced4da;
  border-right: none;
}
.req-input-field {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.margin-top-8 {
  margin: top 8%;
}
.color-red {
  color: red;
  font-size: 12px;
}
.back-arrow-icon {
  font-size: 2rem;
  border-radius: 50%;
  border: 1px solid #516ee3;
  color: #516ee3;
  margin-right: 1rem;
}
.back-arrow-icon:hover {
  color: #ffffff;
  background-color: #516ee3;
  cursor: pointer;
}

.border-radius-4 {
  border-radius: 4px;
}
.border-colo-fff {
  border: 1px solid #fff;
}
.width-100 {
  width: 100%;
}
.bodybox {
  padding: 4px;
}
.nav-link.disabled-tab {
  color: #acacad;
  cursor: default;
  background-color: #f0f0f0;
}
textarea:focus {
  outline: none !important;
  border-color: #3b5998 !important;
}
.dropdown-raw {
  padding: 7px;
  border-radius: 4px;
  border: 1px solid #dfdfdf;
  display: block;
  float: right;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 4px;
}
