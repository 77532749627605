/* .loader-box{
    position: fixed;
    width:100vw;
    height:100vh;
}
.loader-main {
    border: 16px solid #f3f3f3; 
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
   */
  /* @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  } */

  .loader-box { 
    background:rgba(0,0,0,0.8); 
    position:fixed; 
    bottom:0; 
    left:0; 
    top:0; 
    width:100%; 
    height:100%; 
    z-index:100;
}
.formwrapper {
    font-family: arial;
    width: 163px;
    height: 109px;
    margin:auto;
    position:fixed; 
    bottom:0; 
    left:0; 
    top:0;
    right: 0; 
    text-align: center;
    color: #000;
    /* background: #c1c1c1; */
    border-radius: 10px;
    animation: spin 2s linear infinite;
}
.img-fluid-image {
    padding: 12px;
    width: 83%;
    margin: 8px 10px;
}



  /* @keyframes spin {
  0% { transform: rotate(0deg); }
  50% { transform: rotate(20deg); }
  0% { transform: rotate(-20deg); }
}  */