@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

*,
body,
html {
  margin: 0;
  padding: 0;
}
.w-600 {
  font-weight: 600;
}
*,
::after,
::before {
  box-sizing: border-box;
}
a {
  -webkit-transition: color 0.3s, background-color 0.3s;
  -moz-transition: color 0.3s, background-color 0.3s;
  -o-transition: color 0.3s, background-color 0.3s;
  transition: color 0.3s, background-color 0.3s;
}

body {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  color: #202124;
  background-color: #f8f8f8;
  padding-top: 65px;
}
.row div h4,
.information-input h4 {
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-size: 20px;
}
.btn {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.btn-primary {
  background-color: #516ee3;
  border-color: #516ee3;
}
.btn-primary:hover {
  background-color: #3c5ad3;
  border-color: #3c5ad3;
}

.container {
  width: 90%;
  max-width: 1440px;
}

.overflow-inherit {
  overflow: inherit !important;
}

table {
  border-collapse: collapse;
}

.header_container {
  transition: padding 225ms, transform 0.3s ease-out;
  z-index: 900;
  top: 0;
  position: fixed;
  box-shadow: 0 2px 12px 0 rgba(36, 50, 66, 0.075);
  left: 0;
  right: 0;
  background: #fff;
  border-bottom: 1px solid #ebe9f1;
  border-radius: 0;
}
input[type="radio"] {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}
.header_container .navbar {
  padding: 0.5rem 0rem;
}

.header_container .navbar-brand {
  margin-right: 3rem;
  padding: 0;
}

.header_container .navbar-brand img {
  max-width: 120px;
}

/* .header_container .nav-link {
    height: 50px;
    line-height: 50px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: .2px;
} */
.header_container .nav-link {
  height: 35px;
  /* line-height: 50px; */
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;
  color: #202124;
  padding: 0 15px !important;
}
.header_container .nav-link.active {
  border-radius: 3px;
  color: #516ee3;
  background: #edf1f2;
}
.header_container .nav-link:hover,
.header_container .nav-link:focus {
  border-radius: 3px;
  color: #516ee3;
  background: #edf1f2;
}

.header_container .nav-link.logoutBtn {
  color: #ea5455;
  background-color: #ea545514;
  border-radius: 3px;
}
.navbar-toggler .icon-bar {
  display: block;
  height: 3px;
  width: 25px;
  background: #516ee3;
  transition: 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  border-radius: 8px;
}
.navbar-toggler .icon-bar + .icon-bar {
  margin-top: 5px;
}
.navbar-toggler .icon-bar:nth-child(2) {
  width: 18px;
}
.card {
  border: 1px solid #ebe9f1;
  background-color: #fff;
  border-radius: 0.428rem;
  overflow: hidden;
  margin-bottom: 20px;
}

.row {
  margin-left: -10px;
  margin-right: -10px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 10px;
  padding-left: 10px;
}

.card {
  border-radius: 4px;
}
.card .card-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #ebe9f1;
  min-height: 48px;
  padding: 5px 15px;
}

.card .card-header h3 {
  font-weight: 600;
  margin: 0;
  font-size: 14px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.card .card-header .headerDes {
  margin-bottom: 5px;
  font-size: 10px;
  letter-spacing: 0.3px;
  padding-top: 5px;
}
.card .card-header .headerIcon {
  cursor: pointer;
  font-size: 26px;
  color: #516ee3;
  cursor: pointer;
  margin-right: 8px;
  background: #afbcf221;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  padding: 6px;
  transition: all 0.7s ease;
}

.card .card-header .card-header-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card .table {
  font-size: 12px;
  border: none;
  /* margin: -1px;
     width: calc(100% + 2px); */
}

.card .table-bordered td,
.card .table-bordered th {
  border: 1px solid #ebe9f1;
}

.card .table tr:nth-child(2n) {
  background: #f3f2f766;
}

.card .table thead th {
  background-color: #f3f2f7;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: left;
  padding: 8px 10px;
}

.card .table.topProcessTbl tbody th,
.card .table.topProcessTbl tbody td {
  padding: 4px 10px;
}

.card .table tbody td {
  text-align: left;
  font-weight: 500;
  padding: 8px 10px;
}

.serverDetailsSec {
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  background: #fff;
  margin-top: -12px;
  padding: 10px 0 12px 20px;
  box-shadow: 0 0px 10px 0 rgba(36, 50, 66, 0.075);
  border-radius: 0 0 4px 4px;
}

.serverDetailsSec .serverUUID {
  display: none;
}

.backBtn {
  border-radius: 100%;
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  padding: 0;
  background: #fff;
  color: #ea5455;
  border: 1px solid #ea5455;
}

.backBtn:hover,
.backBtn:focus {
  background: #ea5455;
  border: 1px solid transparent;
}

.iconBtn {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: 0;
  background: #516ee3;
  border: none;
  color: #fff;
  z-index: 9 !important;
}
.addIconBtn {
  font-size: 21px !important;
  padding: 2px !important;
}

.serverDetails {
  padding: 10px;
}

.serverDetails .sDetailsCol {
  letter-spacing: 0.5px;
  /* float: left;
    width: 25%; */
  padding: 0px 10px;
  min-height: 20px;
  display: flex;
  align-items: center;
}

/* .notificationdiv{
    display:flex;
} */
.notificationdiv label {
  min-width: 100px;
}
.serverDetails .sDetailsCol label,
.notificationdiv label {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  color: #666;
  margin: 0;
  padding-right: 5px;
}
.serverDetails .domainDetailsInfo .sDetailsCol label {
  min-width: 140px;
}
.serverDetails .sDetailsCol strong,
.notificationdiv strong {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.sDetailsCol strong {
  word-break: break-all;
}

.selectServer {
  min-width: 300px;
  margin-right: 20px;
  z-index: 99;
}

.serverDetails .sDetailsCol strong .selectServer,
.card-header .sDetailsCol strong .selectServer,
.card-header .sDetailsCol strong img {
  display: none;
}

.card-header .sDetailsCol {
  letter-spacing: 0.5px;
  padding: 0px 10px;
  min-height: 20px;
  display: flex;
  align-items: center;
}

.card-header .sDetailsCol label {
  margin: 0;
  padding-right: 5px;
}

.subTitleOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-top: 10px;
}

.subTitleOuter .selectMtTimezone {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subTitleOuter .selectMtTimezone .timeZoneSelect {
  min-width: 150px;
  font-size: 13px;
  font-weight: 500;
}
.serverDetailsSec .selectMtTimezone .myTimezone {
  padding-right: 70px;
}
.myTimezone span:hover {
  cursor: pointer;
}
.serverDetailsSec + .card .collapse.show .table-responsive {
  max-height: 522px;
  overflow: auto;
}
.subTitleOuter .selectMtTimezone .myTimezone {
  font-size: 13px;
  font-weight: 600;
  margin-right: 8px;
  letter-spacing: 0.3px;
}

.sectionHeading {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: center;
  color: #ea5455;
  margin-bottom: 5px;
}

.chartInnerDiv {
  padding: 0 0px 10px 0px;
  margin-top: -40px;
}

.largeChartInnerDiv {
  padding: 0 10px 10px 10px;
  margin-top: -37px;
}

.loginForm {
  max-width: 400px;
  margin: 0 auto;
  padding: 40px 0;
}

.loginForm .card {
  padding: 30px;
}

.loginLogo {
  text-align: center;
  padding-bottom: 40px;
}

.loginLogo img {
  max-width: 150px;
}

.loginForm .card .card-title {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 15px;
}

.loginForm .card .form-group label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.loginForm .card .form-group .form-control {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  height: 40px;
}

.user-button-login .btn,
.primary-btn {
  color: #fff !important;
  background-color: #516ee3 !important;
  border-color: #516ee3 !important;
  height: 42px;
  min-width: 120px;
}

.paginationSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.paginationSec .totalPage {
  max-width: 100px;
  width: 100%;
}
.paginationSec nav {
  display: inline-block;
  padding-top: 10px;
  /* padding-top: 1rem; */
}

.paginationSec nav .page-link {
  padding: 5px 12px;
  font-size: 13px;
  font-weight: 600;
}

.folderName {
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  margin-top: -5px;
}

.folderName span {
  color: #ea5455;
}

.checkboxLabel {
  display: block;
  padding-left: 15px;
  text-indent: -15px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.checkboxInput {
  width: 13px;
  height: 13px;
  margin: 0;
  vertical-align: bottom;
  position: relative;
  top: -2px;
  *overflow: hidden;
  margin-right: 7px;
}

.select_startdatetime {
  margin-top: -3px;
}
.select_startdatetime .addEditBtn {
  height: 36px;
}

.select_startdatetime label {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
}

.select_startdatetime .react-datepicker-wrapper,
.select_startdatetime .react-datepicker-wrapper input {
  width: 100% !important;
}

.select_startdatetime .react-datepicker-wrapper input {
  border-color: #ddd;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
}

.tableActionBtns {
  display: flex;
  align-items: center;
}
.actionIcon,
.iconBtn {
  color: #516ee3;
  cursor: pointer;
  font-size: 16px;
  background: #afbcf221;
  border-radius: 100%;
  padding: 6px;
  height: 30px;
  width: 30px;
  transition: all 0.7s ease;
}
.actionIcon:hover,
.iconBtn:hover,
.noteBtn:hover,
.actionIcon:focus,
.iconBtn:focus,
.noteBtn:focus {
  color: #fff;
  background: #516ee3;
  box-shadow: none;
}
.tableActionBtns .actionIcon {
  margin-right: 8px;
}
.no-notification {
  display: flex;
  /* align-items: center; */
}
td .notificationdiv .no-notification svg {
  font-size: 15px;
  margin-left: 0;
  color: #516ee3;
  margin-right: 5px;
}
.servernotifi.no-notification svg {
  margin-right: 5px;
  /* font-size: 23px !important;
    margin-left: -6px !important; */
}
.deleteIcon {
  color: #ea5455;
  background-color: #ea545514;
}
.deleteIcon:hover,
.deleteIcon:focus {
  color: #fff;
  background-color: #ea5455;
}
.addEditBtn {
  font-size: 12px;
  /* height: 32px; */
  min-width: 80px;
  font-weight: 600;
  padding: 5px;
}
.monitorListSec {
  border-bottom: 1px solid #f5f6fef2;
  padding: 4px 2px;
}
.listactive {
  background: #fafafa;
  border: 1px solid #516ee3;
}
.monitorListSec:hover {
  background: rgb(250, 250, 250) none repeat scroll 0% 0%;
}
.monitorListSec .row {
  /* padding: 5px 15px; */
  min-height: 48px;
  font-size: 14px;
}
.monitorListSec .row .htt,
.monitorListSec .htt {
  background: #fff3cd85;
  padding: 0 5px 3px 6px;
  border-radius: 2px;
  color: #ed6c6d;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.4px;
}
.monitorListSec .row .https,
.monitorListSec .https {
  background: #1ca83408;
  padding: 2px 4px;
  border-radius: 2px;
  color: #28a745;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.4px;
}
.monitorURLchart .echarts-for-react {
  margin-top: -40px;
}
.serverStatisticsSec {
  border: 1px solid #ebe9f1;
  padding: 11px 20px 0 20px;
  background: #ffffff52;
  margin-bottom: 20px;
  box-shadow: 0 2px 12px 0 rgba(36, 50, 66, 0.075);
  border-radius: 6px;
  overflow: hidden;
}

.serverStatisticsSec .subTitleOuter {
  margin: -10px -20px 18px -20px;
  padding: 10px 20px;
  background: #fff;
  border-bottom: 1px solid #ebe9f1;
}

.serverStatisticsSec .subTitleOuter .sectionHeading {
  margin-bottom: 0;
}

.dateTimeFilterSec {
  padding: 20px;
}

.dateTimeFilterSec h4 {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 6px;
}

.respondTimeSec {
  background-color: #fff3cd;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  margin-top: 15px;
  font-weight: 600;
}

.respondTimeSec span {
  color: #ea5455;
}

.statusSec {
  box-shadow: 6px 6px 29px 0 rgba(154, 171, 182, 0.19);
  border: 1px solid #ebe9f1;
  background-color: #fff;
  border-radius: 0.428rem;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 20px;
}

.statusSec h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.statusSec p {
  background-color: #fff3cd;
  padding: 4px 10px 4px 10px;
  margin-bottom: 0;
  font-weight: 600;
  clear: both;
  float: left;
  margin-top: 10px;
  font-size: 13px;
  letter-spacing: 0.3px;
}

.serverInfoTabs {
  background: #f5f5f580;
  margin-bottom: -2px;
}

.serverInfoTabs .nav-item .nav-link {
  border-radius: 8px 8px 0 0;
  padding: 12px 30px 12px 30px;
  margin-right: 10px;
  border: 1px solid #edebf2;
  border-bottom: none;
  font-size: 14px;
  font-weight: 500;
  background-color: #f2f2f2;
  color: #000;
}

.setting-tabs-view.serverInfoTabs .nav-item .nav-link {
  padding: 12px 26px 12px 26px !important;
}

.serverInfoTabs.customsetting {
  margin-right: 2px !important;
}

.serverInfoTabs .nav-item .nav-link.active {
  background: #fff;
  border-top: 3px solid #516ee3;
  color: #516ee3;
  font-weight: 600;
}

.serverInfoTabsTitle {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #ebe9f1;
  min-height: 48px;
  padding-bottom: 10px;
  margin-bottom: 25px;
}

.serverInfoTabsTitle h3 {
  font-weight: 600;
  margin: 0;
  font-size: 16px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.textRed {
  color: #ea5455;
}

/* Developers css start */
.back_button_a {
  padding-left: 20px;
}

.back_a_main_div {
  padding-top: 20px;
}

.header {
  width: 21%;
  margin-top: 20px;
  margin-left: 7%;
  margin-bottom: 12px;
}

.domain-loader {
  position: absolute;
  top: 49px;
  left: -18px;
}

.NextButton {
  float: right;
  /* margin-right: 143px; */
  margin-bottom: 36px;
}

.loader_div_width {
  width: 10px;
}
.addIcon {
  color: #516ee3;
}

.cloudflare_loader {
  position: absolute;
  top: 72px;
  left: 203px;
}

/* .autoUpdateCheckBox {
    margin-left: 139px;
} */
.domain_informationtable .dns_table input,
.autoUpdateCheckBox input,
.dropdownContent input,
.performanceSetting input,
.notification input {
  width: auto;
}
.domain_informationtable label.dns_table {
  padding: 15px;
}
/* .seo-monitoring {
    /*align-items: center;
    display: flex;
    flex-direction: column;
     padding: 35px;*/
/* height: 88vh; 
} */

.social-media-form-listing {
  margin-top: 30px;
}

.dashboardTd .monitorListSec .col-md-3 {
  padding: 0;
}

.monitor-url-listing {
  margin-top: 60px;
}
.monitor-url-listing h3 {
  letter-spacing: 0.8px;
  font-size: 16px;
  font-weight: 600;
}
.belowBtns {
  text-align: center;
  display: inline-block;
  width: 100%;
}
.NextButton {
  float: none;
  margin-bottom: 0;
}
/* .PreviousBotton {
    margin-left: 150px
} */

/* new css */
.hem {
  width: 35px;
  height: 5px;
  background-color: black;
  margin: 6px 0;
}

.dash-form {
  max-width: 800px;
  margin: auto;
  border: 1px solid #9696966b;
  border-radius: 10px;
  box-shadow: 0px 0px 13px 0px #dedede;
  background: #fff;
  transition: ease-in-out all 300ms;
  overflow: hidden;
}

.dash-form:hover,
.dash-cloud:hover {
  box-shadow: 0px 0px 6px 3px rgba(181, 192, 238, 0.87);
}
.dashboardCard {
  box-shadow: 0 0 2px 0px rgba(187, 183, 183, 0.2), 0 2px 4px 0px rgba(155, 154, 154, 0.2);
}
.dash-form h2 {
  font-size: 25px;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebe9f1;
  min-height: 60px;
  font-weight: 600;
  letter-spacing: 0.8px;
  padding: 5px 15px;
}

.dash-or strong {
  line-height: 8;
  text-align: center;
  display: block;
}

.dash-cloud {
  padding: 35px;
  border: 1px solid #9696966b;
  border-radius: 10px;
  box-shadow: 0px 0px 13px 0px #dedede;
  background: #e8e8e861;
  transition: ease-in-out all 300ms;
}

.dash-cloud a {
  color: #000;
  font-size: 22px;
  text-decoration: none;
  text-align: center;
  display: block;
}

.form-domain {
  display: flex;
  position: relative;
}

.form-domain input {
  width: 100%;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  box-shadow: none;
}

.form-domain button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background: #f48120;
  border-color: #f48120;
  box-shadow: none;
}

.form-domain .do-error {
  position: absolute;
  bottom: -22px;
  color: rgb(255, 0, 0);
  font-size: 12px;
}

.user-button-login {
  text-align: center;
}

.info-in {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-in .information-input {
  display: flex;
  align-items: baseline;
}
#updateDomain .information-input {
  display: flex;
  align-items: center;
}
.info-in .information-input h4 {
  font-size: 20px;
}

.info-in .information-input p {
  margin-left: 15px;
  font-size: 20px;
  margin-bottom: 0;
}

.connet-clo a {
  color: #000;
  text-decoration: none;
}

.asd {
  overflow-x: scroll;
  margin: auto;
}

.domain_information_table {
  max-width: 1140px;
  margin: auto;
}

/* .card {
    margin: 10px 0;
} */
.form-login {
  max-width: 550px;
  border: 1px solid #fff;
  border-radius: 10px;
  margin: auto;
  padding: 25px;
}

.form-login form .dlne {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form-login form .dlne:nth-child(1) {
  margin-bottom: 35px;
}

.form-login form .dlne .iconf {
  color: #2485e8;
  background: #fff;
  padding: 11px;
  border-radius: 4px 0 0 4px;
  min-width: 39px;
  text-align: center;
}

.form-login form .dlne input {
  border-radius: 0px 4px 4px 0;
  border: 0;
  box-shadow: none;
  border-left: 1px solid #000;
}

.form-login form .dlne input[type="checkbox"] {
  width: 13px;
  margin-right: 8px;
}

.form-login form button {
  margin: auto;
  display: block;
  padding: 10px 45px;
  border: 2px solid #fff;
  background: #2485e8;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
}

.form-login form button:hover {
  background: #fff;
  color: #2485e8;
}

.chart-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

.no-data-found {
  text-align: center;
  color: red;
}

.date-filter-class {
  width: 163px !important;
}

.filterButton {
  margin-top: 33px;
}

/* Developers css End */

/* style css */
#captureData .tab-content .tab-pane label {
  font-weight: 500;
  font-size: 15px;
}

#captureData .tab-content .tab-pane textarea {
  min-height: 85px;
}

.bluesum {
  border: none;
  color: #fff;
  background: #516ee3;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  padding: 0;
}

.bluesum:hover,
.bluesum:focus,
.bluesum:active {
  color: #fff;
  background: #516ee3;
}

.disallowedbuttons {
  display: flex;
  align-items: center;
}

/* .bluebutton {
    background: #516ee3;
    color: #fff;
    display: block;
    margin: auto;
    border: none;
} */

.bluebutton {
  background: #516ee3;
  color: #fff;
  display: block;
  margin: auto;
  border: none;
  min-width: 100px;
  font-size: 14px;
  font-weight: 500;
  padding: 9px;
}

.bluebutton:hover {
  background: #516ee3;
  color: #fff;
}

.remove-sev {
  margin: 7px auto 0;
  border: none;
  color: #fff;
  background: #ea5455;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  padding: 0;
}

.add-sev {
  background: #516ee3;
}
.modal-header {
  padding: 12px 15px;
}
.modal-header .close {
  opacity: 1;
}
.modal-header .close span {
  font-weight: 300;
  font-size: 36px;
  line-height: 18px;
}
.modal-dialog .btn {
  font-size: 14px;
  font-weight: 400;
  padding: 8px 15px;
}

.charts-list {
  display: flex;
  /* padding: 26px; */
  justify-content: space-around;
  border: 1px solid #ebe9f1;
  margin: 2px;
  border-radius: 2px;
  background-color: white;
  font-size: 16px;
  font-weight: 500;
}

.charts-list li {
  list-style-type: none;
  cursor: pointer;
}

.cross-charts {
  position: absolute;
  right: 15px;
  font-size: 22px;
  top: 11px;
  cursor: pointer !important;
  width: 10px;
  height: 10px;
  z-index: 9;
}

.open-notification {
  position: absolute;
  z-index: 9;
  right: 133px;
  top: 71px;
  font-size: 20px;
  color: #5f5fcc;
}

.details-page .echarts-for-react {
  height: 1200px !important;
}

.checkbox-height {
  margin-left: 5px;
}

.notificationSetting .notificationTitle {
  border-top: 2px solid #eee;
  margin-top: 10px;
  padding-top: 12px;
}

.notificationSetting .notificationTitle h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
}

.notificationSetting ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  width: 100%;
}

.notificationSetting ul li {
  width: 50%;
  float: left;
  padding-right: 15px;
}

.notificationSetting ul li label {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.notificationSetting ul li label span {
  font-size: 13px;
}

.notificationSetting ul li label input {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  margin-right: 10px;
}

.notificationSetting ul li label input:checked + span {
  color: #516ee3;
  font-weight: 600;
}
.moniterImges {
  max-height: 30px;
  max-width: 50px;
  border: 1px solid #edebf2;
  padding: 4px;
  margin: 0 3px;
  min-height: 30px;
  object-fit: contain;
}
.moniterImgesDisabled {
  opacity: 0.3;
  filter: grayscale(100%);
  border: 1px solid #dfdfdf;
}

.setLoaderPosition {
  position: absolute;
  z-index: 2;
  margin-left: 45%;
}

.myTAb .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: #495057;
}

.myTAb .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #516ee3;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.charts-screen-tabs {
  padding-top: 10px;
  margin-bottom: -2px;
}

.charts-screen-tabs .btn {
  border-radius: 8px 8px 0 0;
  padding: 12px 30px 12px 30px;
  margin-right: 10px;
  border: 1px solid #edebf2;
  border-bottom: none;
  font-size: 14px;
  font-weight: 500;
  background-color: #f2f2f2;
  color: #000;
}
.charts-screen-tabs .btn:hover,
.serverInfoTabs .nav-item a:hover {
  background: #fff;
  color: #516ee3;
}

.charts-screen-tabs .btn:focus {
  box-shadow: none;
}

.charts-screen-tabs .btn-primary {
  background: #fff;
  border-top: 3px solid #516ee3;
  color: #516ee3;
  font-weight: 600;
}

.charts-screen-data {
  border-top-left-radius: 0;
}

.historyBlueIcon {
  cursor: pointer;
  width: 13px;
  margin-left: 5px;
  height: 13px;
}

.serversNameInfo {
  display: flex;
  justify-content: space-between;
}
.serverSettingData {
  padding: 40px;
}
.serverSettingUUID {
  display: flex;
  align-items: center;
}

/*  ###########   Responsive Queries  ############ */
@media screen and (max-width: 767px) {
  body {
    padding-top: 66px;
  }

  .container {
    width: 100%;
  }

  .header_container .navbar {
    padding: 0.5rem 0;
  }

  .serverDetailsSec {
    /* margin: -8px -15px 8px -15px;
        padding: 8px 0 8px 15px; */
    display: grid;
    margin-top: -5px;
  }
  .serverDetailsSec .serverListServices .selectServer {
    margin-bottom: 20px;
  }
  .serverDetailsSec .serverDetailsSecIcons {
    display: flex;
    margin-bottom: 10px;
  }
  .serverDetailsSecSelect {
    display: grid;
  }
  .charts-screen-tabs .btn {
    padding: 10px 15px;
    margin-right: 8px;
  }

  .navbar-toggler:focus {
    outline: none;
  }

  .serverIcons {
    display: grid !important;
    align-items: center;
    position: relative;
  }
  .serverIcons img {
    margin-bottom: 10px;
    position: absolute;
    top: -38px;
    z-index: 9;
    left: 90px;
  }
  .navbar-toggler:focus {
    outline: none;
  }

  .serverDetails .domainPageMonitor .col-md-4 div label {
    padding: 0 15px;
  }

  #headerNvBar ul {
    padding: 10px 20px;
    text-align: center;
    max-width: 100%;
    margin: 0 auto;
    max-width: 350px;
  }

  #headerNvBar ul li {
    padding: 2px 0;
  }

  .header_container .nav-link {
    height: auto;
    font-size: 16px;
    justify-content: center;
    font-weight: 600;
  }

  .mobMenuHeader {
    display: flex;
    padding: 10px 22px;
    border-bottom: 2px solid #eee;
    justify-content: space-between;
    align-items: center;
    min-height: 70px;
  }

  .header_container .mobMenuHeader .navbar-brand img {
    max-width: 140px;
  }

  .serverStatisticsSec {
    border: none;
    padding: 0;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    overflow: hidden;
  }

  .serverStatisticsSec .subTitleOuter {
    margin: 0;
    padding: 10px 15px 15px 15px;
    background: #fff;
    border: 1px solid #ebe9f1;
    display: inline-block;
    margin-bottom: -4px;
    border-radius: 6px 6px 0 0;
    position: relative;
    z-index: 9;
    width: 100%;
  }

  .serverStatisticsSec .subTitleOuter .sectionHeading {
    /* margin-bottom: 10px; */
    margin-bottom: 0px;
    text-align: left;
    font-size: 18px;
  }

  .serverDetails .sDetailsCol {
    padding: 0px 5px;
  }

  .notificationCol {
    padding: 9px 5px 0 5px;
  }

  .serverListServices {
    position: relative;
    flex-direction: column;
    width: 100%;
    /* padding-left: 40px;*/
    /* padding-left: 15px;
        padding-right: 10px; */
    width: 100%;
  }

  .serverListServices .mr-2 {
    position: absolute;
    z-index: 99;
    top: 26px;
    left: -13px;
  }
  .user-button-login .btn,
  .primary-btn {
    height: 32px;
    min-width: 75px;
  }
  .dash-form {
    padding: 20px;
  }
  .seo-monitoring {
    padding: 10px 0px;
  }
  .dashboardContent {
    display: grid !important;
  }
  .tableScroll {
    overflow-y: auto;
    width: 100%;
  }
  .monitorListSec .row div div .iconBtn {
    margin: 10px 0;
  }
  .monitorSever {
    display: grid !important;
  }
  .smallCard {
    width: auto !important;
  }
  .domainPage {
    margin-top: 10px;
    padding-bottom: 8px;
  }
  .domainPage .serverListServices {
    margin-left: 10px;
    align-items: flex-start;
    width: auto;
  }
  .domainPage .selectServer {
    min-width: 225px;
    margin-right: 15px;
    margin-bottom: 0px !important;
  }
  .serverDetails .domainPageMonitor {
    margin-bottom: 35px;
    border-bottom: 1px solid #a4a4a4;
    padding-bottom: 20px;
  }
  .serverDetails .domainPageMonitor .col-md-4 div label {
    font-weight: 600;
  }
  .domainEmail {
    display: grid;
  }
  .paginationSec {
    display: grid;
    justify-content: center;
  }
  .mp-0 {
    padding: 0;
  }
  .serverInfoTabs {
    margin-top: 10px;
  }
  .topbarFixMob {
    position: relative;
  }
  .topbarFixMob .smlBlueIcons {
    position: absolute;
    right: 15px;
    top: 10px;
  }
  .topbarFixMob .serverListServices .selectServer {
    margin-bottom: 8px;
  }
  .charts-screen-tabs {
    overflow: auto;
    white-space: nowrap;
  }
  .flexDisableMob {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .flexDisableMob .serverDetailsHead {
    flex-direction: column;
    width: 100%;
  }
  .cloudMetaData {
    border-top: 1px solid #eee;
    padding-top: 10px;
  }
  .cloudMetaData .d-flex {
    align-items: center;
    justify-content: space-between;
  }
  .cloudMetaData .d-flex h6 {
    padding-left: 0px !important;
    font-size: 12px;
    margin: 0 !important;
  }
  .cloudMetaData .d-flex .myTimezone {
    padding-left: 20px !important;
  }
  .cloudMetaData .d-flex .myTimezone span {
    font-size: 12px;
  }
  .cloudMetaData .d-flex .myTimezone span svg {
    width: 13px;
  }
  .fixedChartTable {
    min-height: 100px !important;
  }
  #notAllowedFile {
    margin-bottom: 0 !important;
  }
  .hiddenMob {
    display: none !important;
  }
  .headerDetailSml {
    font-size: 12px;
    margin-top: 4px;
    margin-left: 0 !important;
    border-top: 1px solid #eee;
    padding: 5px 0px 3px 0;
  }
  .headerDetailSml .listedBg {
    padding: 1px 4px;
    font-size: 11px;
    border-radius: 2px;
  }
  .dnsDetailsList {
    border-bottom: 1px solid #eee;
    padding-bottom: 6px;
  }
  .nowrap_th th {
    white-space: nowrap;
  }
  .command-title {
    font-size: 12px;
  }
  .monitorListSec .d-flex .col-md-2 {
    max-width: 65px;
    padding-right: 0;
  }
  .monitorListSec .d-flex .col-md-2.monitorActionIcons {
    max-width: 85px;
  }
  .monitorListSec .d-flex .webAdd {
    width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .serverSettingUUID {
    display: flex;
    align-items: center;
  }
  .publicPrivateKey,
  .serverSettingData {
    padding: 20px !important;
  }
  .serverInfoTabs {
    margin-top: 0px;
    padding: 10px 10px 0 10px;
    white-space: nowrap;
    overflow: auto;
    display: flex !important;
    width: 100%;
    flex-wrap: inherit;
  }
  .serverInfoTabs .nav-item .nav-link {
    border-radius: 6px 6px 0 0;
    padding: 8px 12px 10px 12px;
    margin-right: 5px;
    font-size: 13px;
  }
  .notificationPerPageFilter {
    position: relative;
  }
  .notificationPerPageFilter .col-md-4 {
    padding: 6px 30px 12px 10px;
  }
  .notificationPerPageFilter .col-md-1 {
    position: absolute;
    bottom: 0px;
    left: 235px;
    width: 50px;
  }
  .emailPageTopFilter {
    margin-top: -14px;
  }
  .emailPageTopFilter h6.card-title {
    padding-bottom: 5px;
  }
  .emailPageTopFilter h6.ml-2 {
    font-size: 13px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 0 !important;
  }
  .card-header .sDetailsCol {
    display: inline-block;
  }
  .form-control {
    font-size: 13px;
  }
  #captureData .tab-content .tab-pane label {
    font-size: 13px;
  }
  .card .card-header {
    z-index: 9;
  }
}
@media screen and (max-width: 360px) {
  .selectServer {
    min-width: auto;
    margin-right: 20px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1199px) {
  .header_container .nav-link {
    line-height: 18px;
  }
}

.overflow-visible {
  overflow: visible;
}
.dashboardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboardContent span {
  font-weight: 600;
  margin: 0;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.topProcessTbl .react-bootstrap-table .table tbody th,
.topProcessTbl .react-bootstrap-table .table tbody td {
  padding: 1px 1px;
}

/* .card .card-header,
.serverDetailsHead,
.card-header .sDetailsCol {
    display: inline-block !important;
} */

.card-header .sDetailsCol {
  padding-left: 0 !important;
  font-size: 12px;
}

.card .card-header h3 {
  margin-bottom: 5px;
  margin-top: 3px;
}

.topProcessTbl .react-bootstrap-table .table thead th {
  padding: 1px 1px;
}
.listedBg {
  width: 100%;
  text-align: center;
  border-radius: 4px;
  padding: 4px 8px;
  max-width: 94px;
  margin: 0 3px;
  font-weight: 500;
  font-size: 13px;
}
.listedBgBack {
  background: #1ca83408;
  color: #28a745;
}
.notlistedBgBack {
  background: #fef1f1;
  color: #dc3545;
}
.b-not-listed {
  position: relative;
  left: 14%;
  margin-top: -2%;
  background: #fef1f1;
  color: #dc3545;
  width: 82px;
  text-align: center;
}

.b-listed {
  position: absolute;
  left: 15%;
  margin-top: -1%;
  background: #1ca83408;
  color: #28a745;
  width: 82px;
  text-align: center;
}
/* .b-ok {
    color: #eef5ee;
    background: #05af05;
    border-radius: 7px;
 } */
.b-ok {
  color: #05af05;
}
svg.b-ok {
  width: 23px;
}
.redIcon {
  color: #dc3545;
}
.b-list-red {
  color: #eef5ee;
  background: #dc3545;
  border-radius: 7px;
}
.b-drop-down {
  position: absolute;
  right: 10px;
  /* top: 11px; */
}
.selectMtTimezone .myTimezone,
.clickToviewDetails {
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
}
.clickToviewDetails {
  text-align: center;
  padding: 8px;
}
.selectMtTimezone .myTimezone svg,
.clickToviewDetails svg {
  width: 17px;
}
.instaBtns button,
.fbBtns button,
button.twitterBtn {
  background: transparent;
  font-size: 14px;
  display: grid;
  align-items: center;
  justify-items: center;
  width: 100%;
  margin: 0 auto;
  color: #3b3b3b;
  font-weight: 500;
  letter-spacing: 0.5px;
  border: none;
}
.twitterBtn + label {
  font-size: 14px;
  color: #3b3b3b;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}
.fbBtns button img {
  /* border: 1px solid #4b69b1;
	border-radius: 100%;
	padding: 6px; */
  width: 100px;
}
button.twitterBtn img {
  /* border: 1px solid #2bb2e1;
	border-radius: 100%;
	padding: 6px; */
  width: 100px;
}
.instaBtns .fbBtns img {
  /* border: 1px solid #ee6c4d;
	border-radius: 100%;
	padding: 6px; */
  width: 100px;
}
.tableOverflow {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
.cardhgt {
  height: 500px;
  overflow: auto;
}
.NextButton {
  color: #fff !important;
  background-color: #516ee3 !important;
  border-color: #516ee3 !important;
}
/*.PreviousBotton {
    color:  #516ee3 !important;
    border: 1px solid #516ee3 !important;
    background-color: transparent !important;
}*/
.dashboardContent span:hover {
  cursor: pointer;
}
.servericon img {
  max-height: 30px;
}
.serverListServices,
.serverDetailsSecIcons,
.serverDetailsSecSelect {
  display: flex;
}
.smallCard {
  width: 500px;
  margin: 0 auto;
}
.charts-list ul.listed {
  padding: 5px;
  margin: 0;
}
.charts-list ul.listed li {
  display: inline-block;
  padding: 4px 13px 5px 13px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  margin: 6px;
  box-shadow: 0 0px 8px 0px rgba(0, 0, 0, 0.1);
  line-height: 24px;
}
.charts-list ul.listed li:hover {
  background-color: #516ee3;
}
.charts-list ul.listed li:hover a {
  color: #fff;
}
.charts-list ul.listed li a {
  color: #516ee3;
  font-size: 13px;
  text-decoration: none;
}
.charts-list ul.listed .active-links {
  background: #516ee3;
}
.charts-list ul.listed .active-links a {
  color: #ffffff;
}
.view-domain-details .monitorListSec .row {
  min-height: 84px;
}
.chart-configure {
  margin-top: 90px;
  text-align: center;
  padding: 12px 25px;
  font-size: 14px;
  color: #ea5455;
  background-color: #ea545514;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 54px;
  font-weight: 500;
}
.chartInfo {
  color: #188914;
  background-color: #54ea642e;
}
.chart-add-button {
  position: absolute;
  /* font-size: 16px; */
  z-index: 2;
  left: 15%;
}
.chartArea {
  overflow: hidden;
}
.card-header div .iconBtn {
  z-index: 9 !important;
  position: relative;
  margin-left: 10px;
}
.domainPage .serverListServices {
  align-items: center;
}
.socialChartBlock div .chartInnerDiv {
  padding: 0 20px;
}
.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.notesHeader .modal-title {
  display: flex;
}
.modal-title {
  font-weight: 600;
  margin: 0;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 16px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.notesHeader + .modal-body p {
  margin-bottom: 0;
}
.dropdownOption .css-26l3qy-menu {
  z-index: 99;
}
.statusText {
  color: #fff;
  padding: 3px 10px;
  border-radius: 2px;
  font-weight: 600;
  display: inline-block;
  margin-right: 8px;
  margin-top: 5px;
}
.modal-body .addServerContent div {
  width: 100%;
}
/* start the tooltip */
.tooltip {
  position: relative;
  opacity: 1;
  display: inline;
  z-index: inherit;
}
.tooltip .tooltiptext {
  display: none;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 25px;
  position: absolute;
  z-index: 999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 8px -2px rgba(0, 0, 0, 0.18);
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}
/* Tooltip size */
.tooltip.tsize-900 .tooltiptext {
  min-width: 900px;
}
.tooltip.tsize-600 .tooltiptext {
  min-width: 600px;
}
.tooltip.tsize-400 .tooltiptext {
  min-width: 400px;
}
.tooltip.tsize-300 .tooltiptext {
  min-width: 300px;
}

.tooltip.bottom .tooltiptext.domaintip::after {
  top: -18px;
  left: 48px;
}

.tooltip .tooltiptext img {
  border: 1px solid #ddd;
  padding: 6px;
  border-radius: 4px;
}
.tooltiptext::after {
  content: "";
  position: absolute;
  border-width: 10px;
  border-style: solid;
}
.tooltip:hover .tooltiptext {
  display: block;
  top: -45px;
  left: -60px;
  cursor: pointer;
}
/* Tooltip Position */

/* Left */
.tooltip.left:hover .tooltiptext {
  top: -15px;
  left: 35px;
}
.tooltip.left .tooltiptext::after {
  top: 20px;
  left: -19px;
  border-color: transparent #c4c4c4 transparent transparent;
}
/* Bottom */
.tooltip.bottom:hover .tooltiptext {
  top: 30px;
  left: -33px;
}
.tooltip.bottom .tooltiptext::after {
  top: -20px;
  left: 27px;
  border-color: transparent transparent #c4c4c4 transparent;
}
/* Bottom */
.tooltip.right:hover .tooltiptext {
  top: 50px;
  left: -150px;
  z-index: "999";
}
.tooltip.right .tooltiptext::after {
  top: -20px;
  left: 150px;
  border-color: transparent transparent #c4c4c4 transparent;
}

/* End the tooltip */
.dashboardContent svg {
  color: #516ee3;
}
.modalNote.card-header {
  display: grid;
}
.domainPageMonitor {
  border-bottom: 1px solid #ebe9f1;
  /* margin-bottom: 35px; */
  padding-top: 15px !important;
}
.domainPageMonitor .chartInnerDiv {
  padding: 0;
  margin-top: 0;
}
.selectDiv {
  float: right;
  margin-top: 20px;
  margin-right: 20px;
}
.serverIcons,
.servericon {
  display: flex;
  align-items: center;
}
.page-item.active .page-link {
  background-color: #516ee3;
  border-color: #516ee3;
}
.page-link {
  color: #516ee3;
}

.overflow-inherit {
  overflow: inherit;
}
.font-13 {
  font-size: 13px;
}
.componentCenter {
  display: flex;
  align-items: center;
}
.serverstatusbg {
  /* background-color: #f5f6fe59; 
    padding: 15px;
    border-radius: 4px;*/
  display: flex;
  align-items: center;
}
.serverstatusbg .statusText {
  margin-right: 10px;
  font-size: 14px;
}
.serverstatusbg .font-13 {
  font-weight: 500;
}
.uptimeIntro {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
}
.uptimeIntro h3 {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}
.uptimeIntro h3 span {
  font-weight: 600;
  font-size: 14px;
  /* color: #516ee3; */
}
.uptimeIntro .upBarChartIcon {
  font-size: 20px;
  color: #ea5455;
  cursor: pointer;
  background: #ea545514;
  border-radius: 100%;
  padding: 5px;
  transition: all 0.7s ease;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.monitorUrlTitle {
  padding: 12px 15px;
  border-bottom: 1px solid #f6f7fe;
}
.monitorUrlTitle .lastTitle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.monitorUrlTitle .lastTitle .clockIcon {
  margin-right: 5px;
  font-size: 18px;
  color: #516ee3;
}
.monitorUrlTitle .hoursValue {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  font-weight: 600;
  max-width: 180px;
}
.monitorUrlTitle .hoursValue span {
  background: #f8f8f8;
  padding: 1px;
  border-radius: 2px;
  min-width: 34px;
  text-align: center;
  font-family: arial;
}
.infoIcon {
  color: #007bff;
  font-size: 14px;
  margin-top: -3px;
  margin-left: -2px;
  margin-right: 4px;
}

/* Server Status classes */
.greenOver95 {
  background: rgb(235, 247, 238) none repeat scroll 0% 0%;
  color: rgb(39, 166, 68);
}
.onabove79 {
  background-color: #fff3cd;
  color: #6a4f01;
}
/* .red-on-blow79{
    background: #fff3cd85;
    color: #ed6c6d;
} */
.no-sufficient-data,
.red-on-blow79 {
  background: rgb(254, 241, 241) none repeat scroll 0% 0%;
  color: rgb(220, 53, 69);
}
.urlRespondTime {
  position: relative;
  bottom: 0px;
  text-align: center;
  padding-bottom: 15px;
  padding-top: 10px;
}
.urlRespondTime label {
  margin-bottom: 12px;
  font-size: 13px;
  font-weight: 500;
}
.domainDetailsList .monitorListSec {
  border-bottom: none;
}
.domainPageMonitor {
  align-items: center;
  padding-top: 0 !important;
}
.domainPageMonitor:nth-child(odd) {
  background-color: #fafafa;
}
.domainPageMonitor:last-child {
  border-bottom: none;
}
.domainPageMonitor .monitorChartHeight {
  max-height: 90px !important;
}

/* .domainmodal .card-header{
    z-index: 99;
} */
.domainmodal .tooltip {
  z-index: 9;
}

.linkIcon {
  font-size: 20px;
  color: #516ee3;
}
.lastTitle strong {
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.5px;
}
.font-9 {
  font-size: 9px;
  margin-left: 3px;
}
.model-width .modal-content {
  width: 625px;
}

.frize-column table {
  border-collapse: separate;
  border-spacing: 0;
  border-top: 1px solid #000;
}
.frize-column td,
.frize-column th {
  margin: 0;
  border: 1px solid #000;
  white-space: nowrap;
  border-top-width: 0px;
}
.frize-column div {
  width: 450px;
  overflow-x: scroll;
  margin-left: 5em;
  overflow-y: visible;
  padding: 0;
}
.frize-column .headcol {
  position: absolute;
  width: 5em;
  left: 0;
  top: auto;
  border-top-width: 2px;
  margin-top: -1px;
}
.frize-column .headcol:before {
  content: "Row ";
}
.frize-column .long {
  background: #8cdba3;
  letter-spacing: 1em;
}

.h300 {
  min-height: 300px;
}
.notActiveTxt {
  padding: 0 30px 0 30px;
  font-size: 20px;
  line-height: 1.6;
  text-align: center;
  font-weight: 400;
}
.notActiveTxt ol {
  margin: 24px 0 40px 0;
  font-size: 20px;
  background: #516ee30f;
  border: 1px solid #516ee3;
  padding: 8px 10px 10px 40px;
  border-radius: 4px;
  text-align: left;
  line-height: 34px;
}
.notActiveTxt ol li a {
  color: #516ee3;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
}

/* Manage Notication table css*/

table.manageNotif {
  width: 1600px;
  margin: 0;
  border: none;
  background-color: #fff;
  border-collapse: separate;
  border-spacing: 0;
  border-left: 1px solid #ccc;
}
table.manageNotif th {
  background: #f3f2f7;
  border-right: 1px solid #999;
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  max-width: 200px;
}
table.manageNotif td {
  background: #fff;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 4px 5px;
}
table.manageNotif th:first-child {
  position: sticky;
  left: 0;
  z-index: 99;
}
table.manageNotif td:first-child {
  position: sticky;
  left: 0;
  border-right-color: #bbb7b7;
  background: #fff;
  z-index: 9;
}

.viewPopUP {
  position: fixed;
  top: 30px;
  z-index: 9999;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  width: 50%;
}
.viewPopUP .customModalBody {
  max-height: 500px;
  overflow: auto;
}
.viewPopUP .customModalBody .container {
  width: 100%;
}

.viewPopUP .close {
  font-size: 2rem;
  font-weight: 300;
  color: #fff;
  text-shadow: none;
  opacity: 1;
}
.viewPopUP .belowBtns {
  padding-bottom: 24px;
}

.react-confirm-alert-overlay {
  z-index: 9999 !important;
}
#ms-addServer .modal-body .addServerContent .card button svg {
  display: none;
}

.defaultImg {
  padding-bottom: 30px;
  max-width: 530px;
  float: right;
}
.blankPageUI {
  padding: 20px 50px;
}
.indicatorArrow {
  position: absolute;
  left: -18.5%;
  top: 24%;
  height: 30%;
}
.topArrow {
  position: absolute;
  right: 50px;
  top: -53px;
  height: 56px;
}
.notActiveTxt .btn {
  font-size: 18px;
  padding: 14px 40px;
  margin-top: 15px;
}
.notActiveTxt .notactivedata {
  font-size: 22px;
  font-weight: 600;
  color: #ea5455;
  line-height: 30px;
  padding-top: 20px;
}
.notActiveTxt .notactivedata strong {
  font-size: 36px;
  color: #ea5455;
  display: block;
  line-height: 40px;
  padding-bottom: 10px;
}
.dataAddIcon > div {
  display: inline-block;
  padding: 0 2px;
}
.dataAddIcon > div .iconBtn {
  padding: 0px !important;
  height: 24px;
  width: 24px;
  margin-top: 0px;
  color: #fff;
  background: #516ee3;
}
.topArrow2 {
  position: absolute;
  right: 20px;
  top: -17px;
  height: 55px;
}

.serverInfoDetails {
  margin: 8px 0 0 0;
}

.modal-footer {
  border-top: 0;
}
.modal-body .dropdownContent .multi-select {
  max-width: 100%;
}
.react-confirm-alert-overlay {
  background: rgba(36, 35, 35, 0.65) !important;
}
.react-confirm-alert .react-confirm-alert-body {
  top: 30px;
  position: absolute;
  padding: 0 !important;
  font-family: Montserrat, Helvetica, sans-serif;
  border-radius: 5px !important;
  color: #000 !important;
  left: 0;
  right: 0;
  max-width: 400px;
  margin: 0 auto;
}
.react-confirm-alert-body > h1 {
  margin-top: 0;
  font-size: 20px;
  padding: 1rem 15px;
  border-bottom: 1px solid #ddd;
}
.react-confirm-alert-body p {
  padding: 1rem 15px;
}
.react-confirm-alert-button-group {
  justify-content: flex-end !important;
  margin-top: 20px;
  padding-bottom: 20px;
}
.react-confirm-alert-button-group button {
  color: #fff;
  background-color: #3c5ad3 !important;
  border-color: #3c5ad3;
  padding: 8px 18px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.react-confirm-alert-button-group button + button {
  color: #fff;
  background-color: #5a6268 !important;
  border-color: #5a6268;
}
.sqlTable {
  min-height: 300px;
}
.largeChartInnerDiv .serverchartTable {
  margin-top: 20px;
}
.sqlheading {
  font-weight: 600;
  margin: 0;
  font-size: 16px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}
.largeChartInnerDiv .serverchartTable .topProcessTbl .react-bootstrap-table .table thead th,
.largeChartInnerDiv .serverchartTable .topProcessTbl .react-bootstrap-table .table tbody td {
  padding: 5px;
}
.loginUserName {
  color: #516ee3;
  text-transform: uppercase;
  margin: 11px 15px;
  font-weight: 500;
  letter-spacing: 1.5px;
  font-size: 13px;
  display: flex;
  align-content: center;
  justify-content: center;
  line-height: 14px;
}
.loginUserName span {
  padding-left: 10px;
}

/*Fixed chart css*/
.fixedChartTable {
  min-height: 320px;
}
.fixedChartPerformancTable {
  min-height: 320px;
  max-height: 320px;
  overflow: auto;
}
/*Fixed chart css end*/

.popupbox {
  position: absolute;
  top: 100px;
  width: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.close:hover {
  cursor: pointer;
}
.garage-content {
  clear: both;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
}

.unread-email {
  border: 1px;
  border-color: #d8d8d8;
  border-top-style: solid;
  border-bottom-style: solid;
  background-color: #eae7e1;
}

.read-email {
  border: 1px;
  border-color: #d8d8d8;
  border-top-style: solid;
  border-bottom-style: solid;
}

.loaderCenter {
  position: absolute;
  top: 44%;
  left: 45%;
  z-index: 2;
}

.command-title {
  font-size: 14px;
}

.command-box {
  border: 1px solid #cac0c0;
  background: #afbcf221;
  border-radius: 2px;
  padding: 5px;
  color: #2c2a2d;
}

.p-relative {
  position: relative;
}

.copy-text-button {
  position: absolute;
  right: 20px;
  font-size: 22px;
  bottom: 5px;
  color: #6767da;
}

.sqlTable .largeChartInnerDiv {
  padding: 0;
  /* margin-top:-50px; */
  margin-top: -35px;
}
.sqlTable .largeChartInnerDiv .card {
  margin: 0;
  padding-top: 10px;
}
.sqlTable .largeChartInnerDiv .card .row {
  padding: 0 15px;
}
.sqlTable .largeChartInnerDiv .card .topProcessTbl {
  margin-top: 15px;
}
.fileSection {
  box-shadow: 0 2px 12px 0 rgba(36, 50, 66, 0.075);
  padding: 0px 21px;
  padding-bottom: 30px;
  border-radius: 5px;
  border: 1px solid #eee;
  margin-bottom: 25px;
  position: relative;
}
.bckupfolderChange input,
.bckupfolderChange .react-datepicker-wrapper,
.bckupfolderChange .date-filter-class.form-control {
  width: 100% !important;
}

.grayProgressbar {
  background-color: #c5c8ca;
}

/* Css for monitor server chart new ui page */
.sidechartnavbar {
  position: relative;
}
.sidechartnavbar .charts-testscreen-tabs {
  list-style: none;
  text-align: center;
  width: 80px;
  margin: 0;
  padding: 10px 0px;
  position: fixed;
  left: 0;
  background: #fff;
  border: 1px solid #ddd;
}
.sidenav {
  position: fixed;
  z-index: 99;
  background: #fff;
  left: 0;
  top: 54px;
  height: 100vh;
  border-right: 1px solid #ddd;
}
.sidenav .nav-item {
  position: relative;
  border-bottom: 1px solid #ddd;
}
.sidenav .nav-item button:hover + .submenu,
.sidenav .nav-item button:active + .submenu,
.sidenav .nav-item button:focus + .submenu {
  display: block !important;
}
.sidenav .nav-item .submenu li a:hover {
  text-decoration: none;
}
.sidenav .nav-item .submenu li.active-links {
  background: #516ee3;
  color: #ffffff;
  border-bottom: 1px solid #738ef8;
}
.sidenav .nav-item .submenu li.active-links a {
  color: #ffffff;
}
.sidenav .nav-item button {
  background: #ffffff;
  color: #516ee3;
  padding: 15px 6px;
  border: 1px solid #ddd;
  min-width: 75px;
  margin-top: -1px;
  border-radius: 0;
  font-size: 13px;
}
.sidenav .nav-item button:hover {
  background: #ffffff;
  color: #516ee3;
}
.sidenav .nav-item button:active,
.sidenav .nav-item button:focus {
  background: #ffffff;
  color: #516ee3;
  box-shadow: none;
  border-right: 5px solid #516ee3;
  border: 1px solid #516ee3;
}

.sidenav .dropdown-menu {
  top: -3px !important;
  left: 75px !important;
  border-radius: 0;
  min-width: 240px;
  padding: 0;
  height: 100vh;
  background: #fff;
}
.sidenav .dropdown-menu li {
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  color: #516ee3;
  font-weight: 500;
}
.sidenav .dropdown-menu li:hover {
  background: #ced7fcc2;
}
.sidenav .dropdown-menu li a {
  color: #516ee3;
}

.noteBtn {
  color: #516ee3;
  cursor: pointer;
  font-size: 16px;
  background: #afbcf221;
  border-radius: 100%;
  padding: 0px !important;
  height: 30px;
  width: 30px;
  transition: all 0.7s ease;
  border: 0;
}

/*tags input start*/

.tagClass {
  font-size: 12px;
  display: inline-block;
  padding: 5px 10px;
  margin: 3px 0;
  cursor: move;
  border-radius: 3px;
  color: #ffffff;
  background: #6b87f7;
  margin-right: 4px;
  font-weight: 600;
}
.tagClass svg {
  font-size: 8px;
  color: #ffffff;
}

/*tags input end*/

/* DARK THEME CSS*/
/* body.dark .header_container .navbar-brand img {
	filter: invert(1) brightness(200);
} */
body.dark {
  background-color: #202634;
  color: #abb7cf;
}
body.dark .header_container {
  background: #293042;
  border-bottom: 1px solid #2d3448;
  box-shadow: none;
}
.darkLogo {
  display: none;
}

body.dark .darkLogo {
  display: block;
}
body.dark .lightLogo {
  display: none;
}
body.dark .darkLayout {
  background: #0f1015;
  padding: 0;
}
body.dark .header_container .nav-link.active,
body.dark .header_container .nav-link:hover,
body.dark .header_container .nav-link:focus {
  color: #b6c2da;
  background-color: #30384c;
}
body.dark .header_container .nav-link.logoutBtn {
  color: #ea5455;
  background-color: #ea545514;
  font-weight: 600;
  text-shadow: none;
}
body.dark .card {
  background-color: #293042;
  border: 1px solid #2d3448;
}
body.dark .card .card-header,
body.dark .serverDetailsSec,
body.dark .serverStatisticsSec .subTitleOuter,
body.dark .page-link {
  background-color: #293042;
  color: #a4b0c7;
}
body.dark .card .table thead th,
body.dark .card .table-bordered td,
body.dark .moniterImges,
body.dark .command-box,
body.dark .serverInfoTabs .nav-item .nav-link,
body.dark .page-link {
  border: 1px solid #2d3448;
}
body.dark .moniterImges,
body.dark .moniterImgesDisabled {
  background: #f0f6fb;
  border-radius: 3px;
  border: 0;
}
body.dark .card .card-header,
body.dark .card .table-bordered th {
  background-color: #293042;
  border-bottom: 1px solid #2d3448;
}
body.dark .card .table thead th {
  background-color: #20263461;
  color: #eaf0f9;
}
body.dark .card .table tbody tr:nth-child(2n) {
  background-color: #20263461;
}
body.dark .card .table tbody tr:nth-child(2n + 1) {
  background-color: #293042;
}

body.dark .header_container .nav-link {
  color: #b6c2da;
}
body.dark .actionIcon:hover,
body.dark .iconBtn:hover,
body.dark .actionIcon:focus,
body.dark .iconBtn:focus,
body.dark .noteBtn:hover,
body.dark .noteBtn:focus {
  color: #516ee3;
  background: #202634;
}
body.dark .actionIcon,
body.dark .iconBtn,
body.dark .deleteIcon,
body.dark .card .card-header .headerIcon {
  background: #30384c;
}
body.dark .card .card-header .headerIcon,
body.dark .actionIcon,
body.dark .iconBtn {
  color: #b6c2e4;
}
body.dark .deleteIcon {
  color: #ea5455;
  background-color: #ea545514;
}
body.dark .moniterImgesDisabled {
  opacity: 0.08;
}

/* body.dark p, body.dark h1, body.dark h2, body.dark h3, body.dark h4, body.dark h5, body.dark h6, body.dark div, body.dark td{
    color: #d4d6d9 !important;
} */
body.dark .serverInfoTabs .nav-item .nav-link,
body.dark .charts-screen-tabs .btn {
  background-color: #293042;
  color: #b6c2e4;
  border: 1px solid #373e55;
}
body.dark .serverInfoTabs .nav-item .nav-link.active {
  background: #0f1015;
  border-top: 3px solid #516ee3;
  color: #516ee3;
}
body.dark .serverStatisticsSec {
  border: 1px solid #31384d;
  padding: 11px 20px 0 20px;
  background: #293042;
}
body.dark .serverStatisticsSec .subTitleOuter {
  border-bottom: 1px solid #373e55;
}
body.dark .sectionHeading {
  font-size: 16px;
  font-weight: 600;
}
body.dark .serverInfoTabs {
  background: transparent;
}
body.dark .serverInfoTabs .nav-item .nav-link.active,
body.dark .charts-screen-tabs .btn-primary {
  background: #30384c;
  border-top: 3px solid #007bff !important;
  border: 1px solid #353535;
  color: #007bff !important;
}

body.dark .css-yk16xz-control,
body.dark input,
body.dark select,
body.dark .css-1pahdxg-control,
body.dark .css-26l3qy-menu,
body.dark .form-control,
body.dark .css-1fhf3k1-control {
  background: #30384c;
  background-color: #30384c;
  border: 1px solid #394158;
  color: #abb7cf;
}
body.dark .css-26l3qy-menu input {
  display: block;
}
/* body.dark .css-26l3qy-menu div:hover{
    background: #595959;
} */
body.dark input {
  border-radius: 4px;
  border: 1px solid #394158;
}
body.dark .modal-header .close,
body.dark .fbBtns {
  color: #fff;
}
body.dark .multi-select .dropdown-container,
body.dark .multi-select .dropdown-content {
  border: 1px solid #394158;
  background-color: #30384c !important;
}
body.dark .multi-select .dropdown-content {
  display: block !important;
  color: #333;
}
body.dark .multi-select .options {
  background: #293042;
}
body.dark .multi-select .select-item {
  color: #fff;
}
body.dark .multi-select .select-item.selected,
body.dark .multi-select .select-item:hover,
body.dark .multi-select .select-item:active,
body.dark .multi-select .select-item:focus {
  background: #848c95;
}
body.dark .css-1uccc91-singleValue {
  color: #afbbd2;
}
body.dark .modal-content {
  background-color: #202634;
  border: 1px solid #2d3448;
}
body.dark .modal-header {
  border-bottom: 1px solid #2d3448;
}
body.dark .monitorUrlTitle .hoursValue span {
  background: #3b445b;
  font-weight: 400;
}
body.dark .monitorListSec,
body.dark .monitorUrlTitle {
  border-bottom: 1px solid #313a4e;
}
body.dark .monitorListSec:hover,
body.dark .monitorListSecLactive {
  background-color: #30384c;
}
body.dark .monitorListSec .row .htt,
body.dark .monitorListSec .htt,
body.dark .respondTimeSec {
  background: #ea545514;
  /* color: #fff !important; */
}
body.dark .monitorListSec .row .https,
body.dark .monitorListSec .https,
body.dark .serverstatusbg .statusText,
body.dark .greenOver95 {
  background: #28a74533;
  /* color: #fff !important; */
}
body.dark .twitterBtn + label {
  color: #fff;
}
body.dark .react-datepicker {
  background: #444;
  border: 1px solid #4f4e4e;
}
body.dark .react-datepicker__header {
  background-color: #373434;
  border-bottom: 1px solid #5b5555;
}
body.dark .react-datepicker-time__input input {
  border: 1px solid #343b4f;
  color: #7c8086;
}
body.dark .select_startdatetime .react-datepicker-wrapper input {
  border-color: #394158;
  color: #7c8086;
}
body.dark .serverDetailsSec {
  border: 1px solid #343b4f !important;
}
body.dark select.form-control {
  border: 1px solid #394158 !important;
  color: #afbbd2;
  font-size: 14px;
  height: 38px;
}
body.dark .serverInfoTabsTitle {
  background-color: transparent;
  border-bottom: 1px solid #353d53;
}

body.dark .serverInfoTabsTitle.flex-column.align-items-start,
body.dark #left-tabs-example-tabpane-3 .serverInfoTabsTitle {
  background-color: #293042;
  border-bottom: 1px solid #293042;
}
body.dark .publicPrivateKey {
  border-color: #353d53;
}
body.dark .card .table-bordered td,
body.dark .card .table-bordered th,
body.dark .card .table td,
body.dark .card .table th {
  border: 1px solid #2d3448;
  color: #b6c2e4;
}

body.dark .tooltip .tooltiptext {
  background: #30384c;
  border: 1px solid rgb(56, 66, 89);
  box-shadow: none;
}
body.dark .tooltip.bottom .tooltiptext::after {
  border-color: #ff1f1f00 transparent #384259 transparent;
}
body.dark .fileSection {
  box-shadow: none;
  border: 1px solid #353d53;
}
body.dark .serverDetails .sDetailsCol label,
body.dark .notificationdiv label {
  color: #afbbd2;
}
body.dark .manageNotif tr td {
  background: #3e3e46 !important;
  border: 1px solid #353535 !important;
}
body.dark .manageNotif tr th {
  background: #262626 !important;
  border: 1px solid #353535 !important;
}
body.dark .listactive {
  background: #30384c;
  border: 1px solid #30384c;
}
body.dark .charts-list {
  border: 0;
  background-color: #0f1015;
}
body.dark .charts-list ul.listed {
  background: #293042;
}
/* body.dark .charts-list ul.listed li {
	border: 1px solid #535151;
	box-shadow: 0 2px 5px 0px rgba(34, 34, 34, 0.91), 0 2px 2px 0px rgba(45, 44, 44, 0.91);
} */
body.dark .charts-list ul.listed li {
  border: 1px solid #353d54;
}
body.dark .charts-list ul.listed li a {
  color: #7c8ab2;
}
body.dark .charts-list ul.listed .active-links a,
body.dark .charts-list ul.listed li:hover a {
  color: #ffffff !important;
}
body.dark .page-item.active .page-link {
  background-color: #30384c;
  border-color: #384157;
}
body.dark .alert-danger {
  background-color: #c7080a;
  border-color: #a80213;
  color: #fff;
}
body.dark .sidenav {
  background: #3c3e40;
  border-right: 1px solid #3c3a3a;
}
body.dark .sidenav .nav-item button {
  background: #3c3e40;
  border: 1px solid #2f2f2f;
}
body.dark .sidenav .nav-item button:hover {
  background: #3c3e40;
  color: #516ee3;
}
body.dark .sidenav .dropdown-menu {
  background: #3c3e40;
}
body.dark .sidenav .dropdown-menu li {
  border-bottom: 1px solid #242323a3;
}
body.dark .notActiveTxt ol {
  background: #30384c;
  border: 1px solid #373e55;
}
body.dark .notActiveTxt {
  color: #abb7cf;
}
body.dark .backBtn {
  background: transparent;
}

/* DARK THEME CSS END*/

.switch {
  margin-bottom: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.tt-n {
  text-transform: none;
}
/* Custom checkbox start */
.customCheckBox {
  background-color: #fff;
  display: block;
  margin: 5px 0;
  position: relative;
}
.customCheckBox label {
  padding: 12px 15px 12px 50px;
  width: 100%;
  display: block;
  text-align: left;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  margin: 0;
  border-radius: 4px;
}
.customCheckBox label:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  background-color: #f5f6fe;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}
.customCheckBox label:after {
  width: 28px;
  height: 28px;
  content: "";
  border: 2px solid #d1d7dc;
  /*background-color: #fff;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
        background-repeat: no-repeat;
        background-position: 1px 2px; */
  border-radius: 4px;
  z-index: 2;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 200ms ease-in;
  background-size: 30px;
}
.customCheckBox input:checked + label:after {
  width: 28px;
  height: 28px;
  content: "";
  border: 2px solid #d1d7dc;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
  background-position: 1px 2px;
  border-radius: 4px;
  z-index: 2;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 200ms ease-in;
  background-size: 30px;
}

.customCheckBox input:checked ~ label {
  color: #516ee3;
}
.customCheckBox input:checked ~ label:before {
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}
.customCheckBox input:checked ~ label:after {
  background-color: #516ee3;
  border-color: #516ee3;
}

.customCheckBox input {
  width: 30px;
  height: 30px;
  order: 1;
  z-index: 2;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}
/* Custom Checkbox End */

.removeSecIcon {
  border: none;
  border-radius: 0 6px 0 2px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: 0;
  position: absolute;
  right: 0px;
  top: 0px;
}

.dark .table thead th,
.dark .table tbody td {
  color: #abb7cf;
  background-color: #293042;
  border-color: #2d3448;
}
body.dark .customCheckBox label:before {
  background-color: #30384c;
}

body.dark .customCheckBox label::after {
  border: 2px solid #30384c;
}
body.dark .customCheckBox {
  background-color: #293042;
}

body.dark .charts-list {
  border: 0;
  background-color: #293042;
}

.error-color-red {
  border: 2px solid #e52b2b !important;
}
.error-string p {
  margin: 0px !important;
}
.publicPrivateKey {
  box-shadow: 0 0px 10px 0 rgba(36, 50, 66, 0.075);
  padding: 30px 30px;
  border-radius: 5px;
  border: 1px solid #eee;
  position: relative;
  max-width: 550px;
  margin-top: 15px;
}
.performanceCheck.customCheckBox label {
  padding-right: 30px;
}
.performanceInfo {
  position: absolute;
  top: 12px;
  right: 5px;
  z-index: 99;
}
.wrongUrl {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Arial";
  min-height: 100vh;
  background: url(../img/404_bg.png) no-repeat rgba(255, 255, 255, 0.699);
  background-size: cover;
  background-position: center;
  margin-top: -65px;
  background-blend-mode: lighten;
}
.wrongUrl .txtSec {
  display: flex;
  align-items: center;
  max-width: 643px;
}
.wrongUrl .txtSec img {
  width: 32px;
  margin: 0 17px;
}
.wrongUrl h1 {
  font-size: 150px;
  font-weight: 800;
  position: relative;
}
/* .wrongUrl h1::after {
	content: '';
	width: 2px;
	height: 180px;
	background-color: #444;
	position: absolute;
	right: -5px;
} */
.wrongUrl .txtSec h4 {
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -3px;
  color: #516ee3;
}
.wrongUrl .txtSec p {
  font-weight: 500;
  font-size: 18px;
  line-height: normal;
  color: #516ee3;
  border-bottom: 2px solid #516ee3;
  padding-bottom: 12px;
}
.wrongUrl .txtSec .BackBtn {
  color: #fff;
  background: #516ee3;
  padding: 10px 15px;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid #516ee3;
  border-radius: 7px;
}
.wrongUrl .txtSec .BackBtn:hover,
.wrongUrl .txtSec .BackBtn:active,
.wrongUrl .txtSec .BackBtn:focus {
  background: #fff;
  color: #516ee3;
  text-decoration: none;
}
.wrongUrl .txtSec .BackBtn svg {
  font-size: 32px;
}
.email-div body {
  width: 81% !important;
  padding-top: 0 !important;
}

.email-div img {
  width: 122px !important;
}
.cursor-pointer {
  cursor: pointer;
}
.cpu-modal-date input {
  height: 37px;
}
.position-relative {
  position: relative;
}

.crone-dropdown-spinner .spinner-border {
  width: 28px;
  height: 28px;
  color: #33335c;
  position: absolute;
  top: 2px;
  right: 20px;
}
.requist-per-second {
  font-size: 10px;
  color: #42555ef0;
  text-transform: none;
}
.w-80 {
  width: 80%;
}
.fixedRightMob {
  display: none;
}

@media screen and (max-width: 991px) {
  .serverDetailsSec {
    margin-top: -5px;
  }
  .header_container {
    border-bottom: none;
    padding: 12px 0;
  }
  .header_container .navbar {
    padding: 0;
  }
  .header_container .navbar-collapse {
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 100%;
    width: 100%;
    z-index: 999;
    visibility: hidden;
    background-color: #fff;
    transition: visibility 0.2s ease-in-out;
    border-top: 2px solid #f5f5f5;
    padding-top: 10px;
  }
  .header_container .nav-link {
    height: auto;
    font-size: 14px;
    justify-content: center;
    font-weight: 600;
    padding: 10px !important;
  }
  .header_container .navbar-collapse.show {
    visibility: visible;
    transform: translateX(-100%);
  }
  .fixedRightMob {
    position: absolute;
    right: 55px;
    display: block;
  }
  #headerNvBar .navbar-nav .nav-item {
    flex-direction: column;
    align-items: center;
  }
  .loginUserName {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 30px;
  }
  .header_container .nav-link.logoutBtn {
    width: 150px;
  }
  .navbar-toggler {
    padding: 0;
    width: 2rem;
    height: 2rem;
  }
  .navbar-toggler[aria-expanded="true"] .icon-bar {
    margin-left: 10px;
  }
  .navbar-toggler[aria-expanded="true"] .icon-bar:first-child {
    -webkit-transform: translateX(-50%) translateY(200%) translateY(0.125rem) rotate(45deg);
    transform: translateX(-50%) translateY(200%) translateY(0.125rem) rotate(45deg);
  }
  .navbar-toggler[aria-expanded="true"] .icon-bar:nth-child(2) {
    background: transparent;
  }
  .navbar-toggler[aria-expanded="true"] .icon-bar:last-child {
    -webkit-transform: translateX(-50%) translateY(-200%) translateY(-0.1875rem) rotate(-45deg);
    transform: translateX(-50%) translateY(-200%) translateY(-0.1875rem) rotate(-45deg);
  }
  body.dark .header_container .navbar-collapse {
    background-color: #293042;
    border-top: 2px solid #374055;
  }
  body.dark .navbar-toggler .icon-bar {
    background: #b6c2da;
  }
  .navbar-nav.hiddenMob {
    display: none;
  }
  #headerNvBar ul {
    padding: 10px 20px;
    text-align: center;
    max-width: 100%;
    margin: 0 auto;
    max-width: 350px;
  }
  .chartDateTimeFilter {
    padding: 15px 20px;
  }
  .chartDateTimeFilter .backBtn,
  .chartDateTimeFilter .serverIcons .mr-2 {
    display: none;
  }
  .chartDateTimeFilter .select_startdatetime .col-md-4 {
    padding-bottom: 13px;
  }
  .chartDateTimeFilter .select_startdatetime {
    padding-right: 15px;
  }
}

.table-sortable > thead > tr > th.sortable {
  cursor: pointer;
  position: relative;
}

.table-sortable > thead > tr > th.sortable:after,
.table-sortable > thead > tr > th.sortable:after,
.table-sortable > thead > tr > th.sortable:after {
  content: " ";
  position: absolute;
  height: 0;
  width: 0;
  right: 10px;
  top: 16px;
}

.table-sortable > thead > tr > th.sortable:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #ccc;
  border-bottom: 0px solid transparent;
}

.table-sortable > thead > tr > th.sortable:hover:after {
  border-top: 5px solid #888;
}

.table-sortable > thead > tr > th.sortable.asc:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 0px solid transparent;
  border-bottom: 5px solid #333;
}
.table-sortable > thead > tr > th.sortable.asc:hover:after {
  border-bottom: 5px solid #888;
}

.table-sortable > thead > tr > th.sortable.desc:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
  border-bottom: 5px solid transparent;
}
.pointer {
  cursor: pointer !important;
}

.schema-table .topProcessTbl .react-bootstrap-table .table thead th,
.schema-table .topProcessTbl .react-bootstrap-table .table tbody td {
  padding-left: 10px;
}

.loader-all-notification {
  width: 14px;
  height: 14px;
  top: 6px;
  left: -45px;
}

.terms-condition-checkbox {
  color: blue;
  cursor: pointer;
}

.terms-condition-checkbox:hover {
  text-decoration: underline;
}

.signup-disabled {
  opacity: 0.4;
  cursor: not-allowed !important;
}

.content-fit-css {
  width: fit-content;
}

.remove-button-md {
  margin-top: 3px;
}

/* Here css regrading email track data filter section */
.emailTrackDataSection {
  border-bottom: 1px solid #ddd;
  align-items: center;
  margin-bottom: 12px;
  background: #fff;
  margin-top: -12px;
  padding: 10px 20px 12px 20px;
  box-shadow: 0 0px 10px 0 rgba(36, 50, 66, 0.075);
  border-radius: 0 0 4px 4px;
}

.email_setting_btn {
  display: flex;
  flex-direction: row-reverse;
}

.emailSettingBtn {
  font-size: 12px !important;
  min-width: 80px !important;
  font-weight: 600 !important;
  padding: 5px !important;
  margin-left: 0px !important;
}

.testMailLink {
  margin-left: 10px;
  font-weight: 500;
  text-decoration: underline;
}

.subHeadTestMail {
  font-weight: 600;
}

.email-event-data {
  color: #516ee3;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
}

.eventDataSection {
  padding: 2px;
  .card {
    margin-top: 10px;
  }
}

.view-details-link {
  font-size: 14px;
  margin-top: -7px !important; 
}